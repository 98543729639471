<template>
    <div>
        <div class="trialdialog-container">
            <img src="../../assets/images/trial.png" />
            <h4>{{ $t('explore.expired.title') }}</h4>
            <p>{{ $t('explore.expired.caption') }}</p>
            <vs-button size="large" block @click="callback(true, true)">
                {{ $t('explore.expired.button') }}
            </vs-button>
        </div>
    
    </div>
    </template>
    
    <script>
    export default {
        name: 'trialDialog',
        props: {
            callback: Function
        }
    }
    </script>
    
    <style scoped>
    p,
    h4 {
        margin: 0;
    }
    
    img {
        margin-bottom: 20px;
    }
    
    .trialdialog-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 40px;
        padding-left: 10px;
    padding-right: 10px;
    box-sizing:border-box;
    }
    </style>
    